import React from 'react';
import {useState, useEffect} from "react";
import {useParams, useSearchParams} from 'react-router-dom';
import CardMarketplace from '../../components/cards/CardMarketplace';
import { getGames } from "../../actions/games";
import { getProducts, getCartByAvailability } from "../../actions/products";
import Pagination from "../pagination/Pagination"
import MarkePlaceGamesDisplay from '../display/MarketPlaceGamesDisplay';
import ProductTypeFilters from "./ProductTypeFilters";

function MarketProducts({store}) {
  // Retrieve query parameters from url
  let [queryParams, setQueryParams] = useSearchParams();
  let search = queryParams.get("search");
  let page = queryParams.get("page") || 1;
  // Recover  game slug from url to filter by game
  let { game_slug } = useParams();
  // Create State for list of games that can be used in filters
  const [games, setGames] = useState([]);
  // Create State for list of products to show
  const [products, setProducts] = useState([]);
  // Create State for page on pagination
  const [pageCount, setPageCount] = useState(1);
  // Product type for filter
  const [productType, setProductType] = useState(null);
  // Market by Availability to be able to display current quantities on modal
  const [cartAvailabilities, setCartAvailabilities] = useState({});

  // Effect to get games from backend, not dependencies as this should be only called once
  useEffect(() => getGames(setGames, store?.uuid), [store]);

  // Effect to get quantity by availability
  useEffect(() => getCartByAvailability(setCartAvailabilities), []);

  // Effect to get products when filter parameters change, always calling page 1
  useEffect(
      () => {
        getProducts(setProducts, setPageCount, game_slug, search, productType, page, store?.user);
      },
      [game_slug, search, productType, store, page]
  );

  // Function in charge to handle page changes to avoid multiple chained effects when changing page/filters
  function changePage(page){
    setQueryParams({...queryParams, page: page})
    const element = document.getElementById('productList');
    element?.scrollIntoView({behavior: 'smooth'});
  }

  //checks if the store sells just one game to not show games display
  function singleGame (games) {
    let single = false;
    if(games.length < 2) {
       single = true;
    }return single;
  }

   //checks if the store has only board games for sale to not show filter by singles
   function onlyBoardGames (games) {
      let onlyBG = false;
      if(game_slug === "juegos-de-mesa"){return true}
      if(games.length === 1 && games[0].slug === "juegos-de-mesa") {
          onlyBG = true;
      }
      return onlyBG;
  }

  return (
    <div className="w-100" id="productList">
      <div className=" border-b">
        {singleGame(games) ? null : <MarkePlaceGamesDisplay store={store} games={games}/>}
        <div className="container">
          <div className="section mt-20">
            {onlyBoardGames(games) ? (
              <div>
                <h2 className="section__title mb-20">
                    {game_slug ? games.find((element) => element.slug === game_slug)?.name : "Juegos de mesa"}
                </h2>
              </div>
            ) : (
              <div>
                <h2 className="section__title mb-20">
                    {game_slug ? games.find((element) => element.slug === game_slug)?.name : "Todos los productos"}
                </h2>
                <ProductTypeFilters productType={productType} setProductType={setProductType}/>
              </div>
            )}
          </div>
          <div className="row ">
            {products.length > 0 ? products.map((product, i) => (
              <CardMarketplace key={i}
                  product={product}
                  cartAvailabilities={cartAvailabilities}
                  setCartAvailabilities={setCartAvailabilities}
              />
            )) : (
                <p className="text-center my-5">Lo sentimos, no se encontraron productos que coincidan con tu búsqueda.</p>
            )}
          </div>
              {products.length > 0 ? (
                <Pagination page={page} changePage={changePage} pageCount={pageCount}/>
              ) : null}
        </div>
      </div>
    </div>
  );
}

export default MarketProducts;
